import React, { useState, useRef } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import axios from "axios"
import { InlineWidget } from "react-calendly"
import useClickOutside from "../helpers/useClickOutside"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Input from "../components/Inputs"
import { DeliveryIcon, CloseIcon } from "../components/Icons"
import { LocalBusinessSchema } from "../schemas-org"

import locales from "../locales.json"

const query = graphql`
  query ContactQueryDE {
    datoCmsContactPage(locale: { eq: "de" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
      contactFormHeading
      contactForm {
        name
        placeholder
        required
        inputType
      }
      contactFormButton
      calendlyText
      calendlyButtonText
      calendlyLink
    }
  }
`

const Contact = props => {
  const lang = props.pageContext.langKey

  const data = useStaticQuery(query)

  const {
    seo,
    heading,
    description,
    contactFormHeading,
    contactFormButton,
    contactForm,
    calendlyText,
      calendlyButtonText,
      calendlyLink
  } = data.datoCmsContactPage

  const getStateValues = () => {
    return contactForm.map(input => {
      return {
        ...input,
        value: "",
      }
    })
  }

  const [state, setState] = useState(getStateValues())
  const [formSubmit, setFormSubmit] = useState(false)
  const [formError, setFormError] = useState(false)
  const [calendlyModal, setCalendlyModal] = useState(false)
  const [accept, setAccept] = useState(false)

  const calendlyRef = useRef(null)
  useClickOutside(calendlyRef, () => setCalendlyModal(false))

  const changeInputValue = (index, value) => {
    const stateValue = [...state]
    const findInput = stateValue[index]
    findInput.value = value
    setState(stateValue)
  }

  const submitFormHandler = async e => {
    e.preventDefault();
  
    if (accept) {
      try {
        const data = {
          name: `${state[0].value}`,
          email: state[1].value,
          comment: `[Valudio]: ${state[2].value}`,
        }
        const response = await axios.post(
          "https://fu-tracksite-web-contact.azurewebsites.net/api/ContactForm",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "x-functions-key":
                "DAmu1aCn6VJ2LE5/5NBY/FAlD7EClCQAUV5Mqgp7DFSKaYDmAxKUsg==",
            },
          }
        )
  
        if (response.status === 200) {
          setFormSubmit(true)
          setFormError(false)
          setState(getStateValues())
        } else {
          throw new Error('Error status code returned')
        }
      } catch (error) {
        setFormError(true)
        setFormSubmit(true)
      }
    }
  }

  const contactFormMessage = () => (
    <div className="contact__overlay">
      <div className="contact__modal">
        <div
          className="contact__modal--close"
          onClick={() => setFormSubmit(false)}
        >
          <CloseIcon />
        </div>
        <div className="contact__modal--icon">
          <DeliveryIcon />
        </div>
        <h4 className="contact__modal--heading">
          {!formError
            ? locales.contact.messageSentHeading.de
            : locales.contact.errorMessageSentHeading.de}
        </h4>
        <p
          className="contact__modal--text"
          dangerouslySetInnerHTML={{
            __html: !formError
              ? locales.contact.messageSentText.de
              : locales.contact.errorMessageSentText.de,
          }}
        />
        <button className="btn btn__full" onClick={() => setFormSubmit(false)}>
          {locales.contact.close.de}
        </button>
      </div>
    </div>
  )

  const CalendlyModal = () => (
    <div className="contact__overlay">
      <div ref={calendlyRef} className="contact__modal calendly__modal">
        <div
          className="contact__modal--close"
          onClick={() => setCalendlyModal(false)}
        >
          <CloseIcon />
        </div>
        <InlineWidget
          url={calendlyLink}
          styles={{ height: "100%" }}
        />
      </div>
    </div>
  )

  return (
    <Layout
      lang={lang}
      location={props.location}
      showContact={false}
      className="main__contact"
      whiteNav
    >
      <Seo
        title={seo.title ?? ""}
        description={seo.description ?? ""}
        meta={seo.description ?? ""}
        image={seo && seo.image && seo.image.url}
        slug="contact"
        schemaMarkup={LocalBusinessSchema}
      />
      {formSubmit && contactFormMessage()}
      {calendlyModal && CalendlyModal()}
      <div className="contact__page background__black">
        <div className="container">
          <div className="contact__container">
            <div className="contact__main">
              <h1
                className="contact__main--heading"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
            <div className="contact__cta">
              <form className="contact__form" onSubmit={submitFormHandler}>
                <h2
                  className="contact__form--heading"
                  dangerouslySetInnerHTML={{ __html: contactFormHeading }}
                />
                {state.map((input, index) => {
                  const { name, inputType, placeholder, required, value } =
                    input
                  return (
                    <div className="contact__form--group" key={index}>
                      <Input
                        name={name}
                        value={value}
                        change={e => changeInputValue(index, e.target.value)}
                        type={inputType}
                        placeholder={placeholder}
                        required={required}
                      />
                    </div>
                  )
                })}
                <div className="contact__form--checkbox">
                  <input
                    type="checkbox"
                    name="privacy"
                    checked={accept}
                    onChange={() => setAccept(!accept)}
                  />
                  <p>
                    {locales.contact.accept.de}
                    <Link to="/privacy-policy/">
                      {locales.contact.privacyPolicy.de}
                    </Link>
                  </p>
                </div>
                <div className="btn__primary--animation">
                  <button
                    type="submit"
                    className="btn btn__primary"
                    disabled={!accept}
                  >
                    {contactFormButton}
                  </button>
                </div>
                <h3 className="contact__form__calendly-text">
                {calendlyText}
                </h3>
                <div
                role='presentation'
                  className="btn btn__secondary calendly-button"
                  onClick={() => setCalendlyModal(!calendlyModal)}
                  onKeyDown={() => setCalendlyModal(!calendlyModal)}
                >
                  {`${calendlyButtonText}  >`}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
